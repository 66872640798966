import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={35}
    s3Path={`portfolio/pregnant/4/`}
  />
)

export default Showcase;
